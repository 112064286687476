<!-- 权限管理-->
<template>
    <div>
        <ctrl-box class="ctrl-box" :data="{}">
            <template>
                <div class="xgroup">
                    <el-link :underline="false" @click="showQuery = !showQuery">
                        <div class="wrap" >
                            <!-- <i class="iconfont icon-sousuo font22" ></i> -->
                            <icon-svg icon-class="icon-search" font-size="27px;"/>
                            <span class="txt" >{{msg('搜索')}}</span>      
                        </div>                
                    </el-link>
                </div>
                <div class="xgroup">
                    <el-link :underline="false" type="primary" @click="doAdd()">
                        <div class="wrap" >
                            <!-- <i class="iconfont icon-add font22" ></i> -->
                            <icon-svg icon-class="icon-tianjia" font-size="27px;"/>
                            <span class="txt">{{msg('添加')}}</span>      
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="doEdit()">
                        <div class="wrap" >
                            <!-- <i class="iconfont icon-edit font22" ></i> -->
                            <icon-svg icon-class="icon-bianji" font-size="27px;"/>
                            <span class="txt">{{msg('编辑')}}</span>      
                        </div>            
                    </el-link>
                    <el-link :underline="false" type="danger" @click="doDelete()">
                        <div class="wrap" >
                            <!-- <i class="iconfont icon-delete font22"></i> -->
                            <icon-svg icon-class="icon-shanchu1" font-size="27px;"/>
                            <span class="txt">{{msg('删除')}}</span>      
                        </div>            
                    </el-link>
                </div>
            </template>
        </ctrl-box>
 
        <!--查询区域-->
        <transition name="el-zoom-in-top">
            <query-box :query="query" v-show="showQuery" labelWidth="60px">
                <template v-slot:default="scope">
                    <el-form-item :label="msg('权限名')">
                        <el-input v-model="scope.query.label"></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('权限表达式')" label-width="100px">
                        <el-input v-model="scope.query.pattern"></el-input>
                    </el-form-item>
                    <el-row>
                        <el-button type="primary" icon="el-icon-search" @click="getPermissions" size="mini">{{msg('搜索')}}</el-button>
                        <el-button icon="el-icon-refresh" size="mini" @click="reset" >{{msg('重置')}}</el-button>
                    </el-row>
                </template>
            </query-box>
        </transition>

        <div class="table-wrap">
            <vxe-grid ref="dataTable" :height="docHeight" 
                resizable border
                show-overflow highlight-hover-row
                highlight-current-row highlight-hover-column highlight-current-column
                :data="permissions"
                :columns="columns"
                @cell-click="checkRow"
            ></vxe-grid>

            <pagenator
                @page-change="handlerPageChange"
                :query="query"
            >
            </pagenator>
        </div>

        <el-dialog :title="dgTitle" :close-on-click-modal="false" 
            :visible.sync="dgVisible" width="400px"
            :fullscreen="isMinScreen">
                <edit :permission="curr" @close-dg="closeEditDialog" />
        </el-dialog>

    </div>
   
</template>

<script>

import CtrlBox from "../../components/CtrlBox.vue";
import QueryBox from "../../components/QueryBox.vue";
import Pagenator from "../../components/Pagination.vue";
import Edit from "./PermissionEdit.vue";
export default {
    name: 'Permission',
    components: {
        CtrlBox: CtrlBox,
        QueryBox: QueryBox,
        Pagenator:Pagenator,
        Edit: Edit
    },
    mounted(){
        this.getPermissions();
    },
    data(){
        return {
            query:{
                url: null,
                name: null,
                length: 20, //每页显示的长度
                start: 0, //起始数量
                pageTotal:0,//总记录数
                pageIndex: 1,
            },

            showQuery: false,
            treeConfig: {
                children: "children",
                line: true,
            },
            columns: [
                { type: 'checkbox', minWidth: 60 },
                {field: "id", title: this.msg("ID"), minWidth: 60},
                {field: "label", title: this.msg("权限名"),minWidth: 200},
                {field: "pattern", title: this.msg("权限表达式"),minWidth: 200},
            ],
            permissions: [],      //权限列表
           
            dgTitle: '',
            dgVisible: false,
            curr: null,
        }
    },
    computed: {
        isMinScreen: {  //是否小屏幕，浏览器
            get() {
                var is = this.$store.state.isMinScreen;
                return is;
            }
        },
        docHeight: {  //表格的高度
            get() {
                var height =  this.$store.state.docHeight;
                return height - 220;
            }
        },
        routeTab: {
            get(){
                try{
                    return this.$store.state.activeMenus[this.$route.path]
                }catch{
                    return null;
                }
            }
        }
    },
    methods:{
        
        getPermissions(refresh){

            if(refresh){
                this.query.pageIndex = 1;
                this.query.start = 0;
            }

            var params = this.$kit.rebuildQuery(this.query);
            this.$axios.post(this.$kit.api.permission.list, params)
                .then((result) => {
                    if(result.status){
                        this.permissions = result.data.data;
                        this.query.pageTotal = result.data.total;
                    }
                });
        },
        reset(){
            this.$kit.clearQuery(this.query);
        },
        //点击行，选中行
        checkRow({row, $event}){
            //如果点击checkbox 则不处理
            if($event.target.className.indexOf("vxe-checkbox--icon")== -1){
                var table =  this.$refs.dataTable;
                table.setCheckboxRow([row], !table.isCheckedByCheckboxRow(row))
            }
        },
        //分页数据发生改变，回调事件：info[0]: 每页显示的数量，info[1]: 起始页
        handlerPageChange(info){
            this.query.length = info[0];
            this.query.start = info[1];
            this.getPermissions();
        },
        doAdd(){
            
            this.curr = { 
                label: null,
                pattern: null,
            };

            this.dgTitle = this.msg("新增权限");
            this.dgVisible = true;

        },
        doEdit(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            if(selectes.length>1){
                this.$message(this.msg('只能编辑一条数据'));
                return;
            }

            this.curr  = selectes[0];

            this.dgTitle = this.msg("编辑权限")+":"+this.curr.label;
            this.dgVisible = true;

        },
        doDelete(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }

            var _this = this;
            this.$confirm(this.fmt('确定要删除权限《%s》吗?', selectes.map(e => e.label)), this.msg('提示'), {
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
                type: 'warning'
            }).then(() => {
                _this.$axios.post(_this.$kit.api.permission.deletes, selectes.map(e => e.id))
                .then((result) => {
                    _this.$message(result.msg || _this.msg('删除成功'));
                    _this.getPermissions(true);
                });
            }).catch((err) => {
                // console.log(err);
            });
        },
        closeEditDialog(type){  //编辑弹窗关闭后的回调事件
            if(type){
                this.getPermissions();
            }
            this.dgVisible = false;
        },
        
    }
    
}
</script>

<style scoped lang="scss">

</style>
