<!--编辑权限-->
<template>
<el-form ref="form" :model="permission" label-width="80px">
    <el-form-item :label="msg('权限名')">
        <el-input v-model="permission.label"></el-input>
    </el-form-item>
    <el-form-item :label="msg('表达式')">
        <el-input v-model="permission.pattern"></el-input>
    </el-form-item>
  
    <div style="text-align:center;">
        <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
        <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
    </div>
</el-form> 
</template>

<script>
export default {
  name: 'PermissionEdit',
  props:{
      permission:Object
  },
  methods:{
    onSubmit(){

        var url = null;
        if(this.permission.id==null){
            url = this.$kit.api.permission.create;
        }else{
            url = this.$kit.api.permission.update;
        }

        var _this = this;
        this.$axios.post(url, this.permission)
            .then(function(result){
                if(result.status){
                    _this.$message(result.msg || (_this.id==null? _this.msg('create-ok'):_this.msg('update-ok')));
                    if(result.status){
                        _this.$emit("close-dg", true);
                    }
                }else{
                    _this.$message(result.msg || (_this.id==null? _this.msg('create-err'):_this.msg('update-err')));
                }
               
            });
    }
  }
}
</script>

<style scoped lang="scss">


</style>
